import { useState } from "react";
import PortableTextPlus from "@/components/portable-text-plus";
import SegmentContainer from "@/components/segments/segment-container";
import Badge from "@/components/badge";
import classNames from "classnames";
import { TriangleSVG } from "@/components/svg";

export default function ListRows({ segment, ...props }) {
  return (
    <SegmentContainer
      className={props.paddingClasses}
      style={{ ...props.bgImageStyle, ...props.bgColorStyle }}
    >
      {segment.heading && (
        <div
          className="mb-8 lg:mb-12 leading-snug font-semibold text-xl md:text-3xl scroll-mt-20"
          id={segment?.heading?.[0]?.children?.[0]?.text}
        >
          <PortableTextPlus value={segment.heading} />
        </div>
      )}
      <ul
        className={classNames(
          "grid gap-y-8 md:gap-y-12 gap-x-6 md:gap-x-8 lg:gap-x-10",
          props.gridColsClasses[segment.numBlocksInRow] ||
            props.gridColsClasses["3"],
          props.widthClasses
        )}
      >
        {segment.blocks.map((block, index) => {
          if (props.toggle) {
            return <ToggleRowItem key={index} block={block} {...props} />;
          } else {
            return <RowItem key={index} block={block} {...props} />;
          }
        })}
      </ul>
    </SegmentContainer>
  );
}

const RowItem = ({ block, ...props }) => {
  return (
    <li className="px-2">
      {block.heading && (
        <div
          className={classNames(
            "font-medium leading-snug",
            props.headingSizeClasses[block.headingSize] || "text-lg"
          )}
        >
          <PortableTextPlus value={block.heading} />
        </div>
      )}
      <div>
        {block.content && (
          <div className="pt-2 md:pt-4 leading-snug prose">
            <PortableTextPlus value={block.content} />
          </div>
        )}
        {block.badges && (
          <div className="mt-3 space-x-2">
            {block.badges.map((badge) => (
              <Badge key={badge.title} {...badge} />
            ))}
          </div>
        )}
      </div>
    </li>
  );
};

const ToggleRowItem = ({ block, ...props }) => {
  const [toggle, setToggle] = useState(true);
  return (
    <li className="px-2">
      <div
        className={classNames("grid grid-cols-[24px_auto] gap-x-2 items-top")}
      >
        <div
          onClick={() => setToggle(!toggle)}
          className={classNames(
            "cursor-pointer h-fit flex justify-center rounded-md select-none",
            "transition-all duration-200 hover:bg-background-dark-gray active:bg-gray-300"
          )}
        >
          <TriangleSVG
            className={classNames(
              "h-[1.5rem] text-light-purple",
              "transition-all duration-200 ease-out",
              !toggle ? "rotate-180" : "rotate-90"
            )}
          />
        </div>

        <div>
          {block.heading && (
            <div
              onClick={() => setToggle(!toggle)}
              className={classNames(
                "pb-2 font-medium leading-snug cursor-pointer",
                props.headingSizeClasses[block.headingSize] || "text-lg"
              )}
            >
              <PortableTextPlus value={block.heading} />
            </div>
          )}

          <div className={toggle ? "invisible h-0" : "visible h-auto md:-mb-4"}>
            {block.content && (
              <div className="leading-snug prose max-w-full">
                <PortableTextPlus value={block.content} />
              </div>
            )}
            {block.badges && (
              <div className="mt-3 space-x-2">
                {block.badges.map((badge) => (
                  <Badge key={badge.title} {...badge} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};
